<template>
  <validation-observer ref="refFormReservation">
    <div class="d-flex justify-content-end py-50">
      <b-button
        :variant="isHideFunction ? 'outline-danger': 'danger'"
        size="sm"
        @click="toggleHideFunction"
      >
        {{ $t(`reservation.${isHideFunction ? 'showFeature' : 'hideFeature'}`) }}
      </b-button>
    </div>
    <div>
      <!-- ANCHOR Trip -->
      <booking-trip-detail
        v-if="selectedTrip"
        :selected-trips="selectedTrip"
        :is-create-group-booking="isCreateGroupBooking"
      />

      <!-- ANCHOR Chi tiết giá GHK -->
      <BookingPriceDetailCombination
        v-if="isCombination"
        :selected-trip="selectedTrip"
        :data-search-flight="getSearchFlight"
        :passenger-data-to-add.sync="sortedPassengers"
        :ancillary-data="ancillaryData"
        @handle-service="handleService"
        @handle-delete-service="handleDeleteService"
      />

      <!-- ANCHOR Chi tiết giá -->
      <booking-price-detail
        v-else-if="getSearchFlight && selectedTrip && sortedPassengers && !isCreateGroupBooking"
        :selected-trip="selectedTrip"
        :data-search-flight="getSearchFlight"
        :passenger-data-to-add.sync="sortedPassengers"
        :ancillary-data="ancillaryData"
        @handle-service="handleService"
        @handle-delete-service="handleDeleteService"
      />

      <!-- ANCHOR Thông tin hành khách -->
      <booking-passenger-detail
        v-if="getSearchFlight && !isEmpty(selectedTrip)"
        :customer-id="customerId"
        :passenger-data-to-add.sync="sortedPassengers"
        :data-search-flight="getSearchFlight"
        :selected-trip="selectedTrip"
        :is-create-group-booking="isCreateGroupBooking"
        :pax-data-group-booking="paxDataGroupBooking"
        @deletePassenger="handleDeletePassenger"
        @multipleQuantityPaxChange="handleMultipleQuantityPaxChange"
      />
      <!-- ANCHOR gợi ý vé hoàn định danh tin hành khách -->
      <!-- <SuggestShopVJ
        v-if="getSearchFlight && !isEmpty(selectedTrip)"
        :passenger-data-to-add.sync="sortedPassengers"
      /> -->

      <!-- ANCHOR - DỊCH VỤ THÊM: HÀNH LÝ, CHỖ NGỒI -->
      <booking-addons-detail
        v-if="sortedPassengers
          && selectedTrip
          && !isCreateGroupBooking
          && (selectedTrip.some(
            trip => sourcesCanBuyAncillary.includes(trip.source) && !['TAP'].includes(trip.clientId))
            || selectedTrip.some(trip => sourcesCanBuySeatMap.includes(trip.source) && !['TAP'].includes(trip.clientId)))"
        :ssr-bags="ssrBags"
        :ssr-others="ssrOthers"
        :seat-map-data="seatMapData"
        :selected-trip="selectedTrip"
        :passenger-data-to-add.sync="sortedPassengers"
        :is-combination="isCombination"
      />

      <BCardGroup
        class="mb-50 mb-md-1"
        deck
      >
        <!-- ANCHOR Thông tin AGC -->
        <BookingAgencyDetail
          :agency-data.sync="agencyData"
          :checkbox-customer-data-replace-agency-data.sync="checkboxCustomerDataReplaceAgencyData"
          :is-role-f1="isRoleF1"
          :enable-send-employee-mail-airline="enableSendEmployeeMailAirline"
          :check-send-employee-mail-airline.sync="checkSendEmployeeMailAirline"
          :is-collapsed="isCollapsedAgencyAndContactDetail"
          @toggleCollapse="toggleIsCollapsedAgencyAndContactDetail"
        />

        <!-- ANCHOR Thông tin khách hàng -->
        <BookingContactDetail
          :customer-data.sync="customerData"
          :checkbox-customer-data-replace-agency-data.sync="checkboxCustomerDataReplaceAgencyData"
          :is-role-f3="isRoleF3"
          :is-collapsed="isCollapsedAgencyAndContactDetail"
          @resetAgencyData="resetAgencyData"
          @toggleCollapse="toggleIsCollapsedAgencyAndContactDetail"
        />
      </BCardGroup>
    </div>

    <div
      v-if="!isHideFunction"
      class="position-sticky"
      style="bottom: 0; z-index: 18;"
    >
      <BRow class="bg-white py-1 rounded-lg">
        <!-- ANCHOR split booking -->
        <BCol
          cols="12"
          md="4"
          :class="`${isMobileView ? 'mb-50' : ''} d-flex-center`"
        >
          <!-- ko cho Tách chặng RT 1G hoặc book đoàn
          ref: https://discord.com/channels/1054696448110903327/1184354819352305714
        -->
          <!-- TH AK Ko cho tách chặng -->
          <BFormCheckbox
            v-if="(['RT', 'MC'].includes(getSearchFlight.type) && !selectedTrip.some(sTrip => ['1G', 'AK', 'TH'].includes(sTrip.source)))
              && !isCreateGroupBooking
              && !isCombination"
            v-model="isSplitItineraries"
            :disabled="isDisableSplitItineraries"
          >
            <span class="font-weight-bolder font-medium-2">{{ $t('flight.splitItineraries') }}</span>
          </BFormCheckbox>
        </BCol>

        <BCol
          cols="12"
          md="4"
          class=""
        >
          <IAmOverlay :loading="loading">
            <div :class="`${isMobileView ? 'w-100 d-flex-between' : 'd-flex-center'}`">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :class="`px-75 px-md-2 ${!isMobileView ? 'mr-2' : ''}`"
                pill
                @click="handleCancel"
              >
                <span class="align-middle text-nowrap">
                  {{ $t('flight.Back') }}
                </span>
              </b-button>

              <b-button
                v-if="isVisiblePayLater"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="warning"
                class="px-75 px-sm-2"
                pill
                :disabled="!canAccess('booking.createBooking') "
                @click="handleValidateBooking(PAY_LATER)"
              >
                <span class="align-middle text-nowrap">
                  {{ $t('flight.createBooking') }}
                </span>
              </b-button>

              <b-button
                v-if="isMobileView && isVisiblePayNow && !isSplitItineraries"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="px-75 px-md-2"
                pill
                :disabled="!canAccess('booking.createBooking')"
                @click="handleValidateBooking(PAY_NOW)"
              >
                {{ $t('flight.paynow') }}
              </b-button>
            </div>
          </IAmOverlay>
        </BCol>

        <BCol
          v-if="!isMobileView"
          cols="12"
          md="4"
          class="d-flex-center"
        >
          <IAmOverlay :loading="loading">
            <b-button
              v-if="isVisiblePayNow && !isSplitItineraries"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              pill
              :disabled="!canAccess('booking.createBooking')"
              @click="handleValidateBooking(PAY_NOW)"
            >
              {{ $t('flight.paynow') }}
            </b-button>
          </IAmOverlay>
        </BCol>
      </BRow>
    </div>

    <CreateBookingConfirmPriceModal
      :booking-data="bookingDataToAdd"
      @create-booking="createBookingHandle"
    />

    <ConfirmHoldBooking
      :booking-data="bookingDataToAdd"
      :is-split-itineraries.sync="isSplitItineraries"
      @create-booking="createBookingHandle"
    />

    <ModalShowGroupBooking :data-group-booking="dataGroupBooking" />

    <ErrorLengthPassengerQHModal
      :error-lists="errLengthNameList"
      :pay-type="payType"
      @handleCreateBooking="modifyNameToCreateBooking"
    />
  </validation-observer>
</template>

<script>
import {
  BRow, BButton, BFormCheckbox, BCol, BCardGroup,
} from 'bootstrap-vue'
import {
  ref,
  computed,
  watch,
  onBeforeUnmount,
  toRefs,
  watchEffect,
} from '@vue/composition-api'
import {
  cloneDeep, isEmpty, map, forEach, groupBy, capitalize,
} from 'lodash'
import { ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import Vue from 'vue'

import store from '@/store'
import { getUserData } from '@/api/auth/utils'
import { useRouter } from '@/@core/utils/utils'
import { convertISODateTime, checkTransitAlert } from '@/@core/utils/filter'
import IAmPaymentPassword from '@/components/IAmPaymentPassword.vue'
import i18n from '@/libs/i18n'
import { apiBooking } from '@/api'

import { validatorPaymentPassword } from '@core/utils/validations/validators'

import useToast from '@useToast'

import useBookingHandle from '../useBookingHandle'
import useCreateBookingHandle from './useCreateBookingHandle'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    ValidationObserver,
    BCardGroup,

    BookingTripDetail: () => import('./BookingTripDetail.vue'),
    BookingContactDetail: () => import('./BookingContactDetail.vue'),
    BookingPassengerDetail: () => import('./BookingPassengerDetail.vue'),
    // SuggestShopVJ: () => import('./SuggestShopVJ.vue'),
    BookingAddonsDetail: () => import('./addons/index.vue'),
    BookingPriceDetail: () => import('./BookingPriceDetail.vue'),
    BookingPriceDetailCombination: () => import('./BookingPriceDetailCombination.vue'),
    CreateBookingConfirmPriceModal: () => import('./CreateBookingConfirmPriceModal.vue'),
    ErrorLengthPassengerQHModal: () => import('./ErrorLengthPassengerQHModal.vue'),
    BookingAgencyDetail: () => import('./BookingAgencyDetail.vue'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    ModalShowGroupBooking: () => import('./ModalShowGroupBooking.vue'),
    ConfirmHoldBooking: () => import('./ConfirmHoldBooking.vue'),

  },
  props: {
    selectedTripProps: {
      type: [Array],
      default: () => [],
    },
    isCombination: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root }) {
    const PAY_LATER = 'payLater'
    const PAY_NOW = 'payNow'
    const {
      FLIGHT_APP_STORE_MODULE_NAME,
      createBooking,
      createGroupBooking,
      fetchListAncillary,
      fetchSeatMap,
      getSearchFlight,
      getAddonsData,
    } = useBookingHandle()

    const {
      sourcesCanBuyAncillary,
      sourcesCanBuySeatMap,
      pooledPaxCheckMaxLength,
      updatePassengerCountToLocalStorage,
      isHideFunction,
      toggleHideFunction,
    } = useCreateBookingHandle()

    const { toastSuccess, toastError } = useToast()
    const { router } = useRouter()

    const passengerData = {
      firstName: '',
      lastName: '',
      title: '',
      birthday: '',
      paxType: '',
      paxId: '1',
      // memberFly: { // Obj fix nested v-for
      //   cardNumber: null,
      //   airline: '', // NOTE - Thêm supplierCode
      // },
      servicePackage: null,
      serviceAmount: null,
      PaxDocuments: [
        {
          paxId: '',
          docType: 'P',
          number: '',
          nationality: null,
          residence: '2023-06-16',
          // startDate: null,
          endDate: '',
          isCountry: null,
        },
      ],
    }
    const customerData = ref(null)
    const checkboxCustomerDataReplaceAgencyData = ref(false)
    const checkSendEmployeeMailAirline = ref(true)
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])
    const selectedTrip = toRefs(props).selectedTripProps

    const isSourceUseShortCodeForDocument = computed(() => selectedTrip.value?.some(it => ['AK'].includes(it?.source)))

    const agencyDataStore = computed(() => store.getters['userStore/getAgencyData'])
    const meDataStore = computed(() => store.getters['userStore/getMeData'])
    const enableSendEmployeeMailAirline = computed(() => agencyDataStore.value?.enableSendEmployeeMailAirline && isRoleF2.value)
    const agencyData = ref(isRoleF1.value
      ? { ...meDataStore.value }
      : {
        ...agencyDataStore.value,
        phoneNumber: agencyDataStore.value?.agencyOwner?.phoneNumber,
      })

    watch(checkSendEmployeeMailAirline, () => {
      if (enableSendEmployeeMailAirline.value) {
        if (checkSendEmployeeMailAirline.value) {
          agencyData.value.phoneNumber = meDataStore.value?.phoneNumber
          agencyData.value.emailAddress = meDataStore.value?.emailAddress
        } else {
          agencyData.value.phoneNumber = agencyDataStore.value?.agencyOwner?.phoneNumber
          agencyData.value.emailAddress = agencyDataStore.value?.emailAddress
        }
      }
    }, { immediate: true })

    const customerId = computed(() => customerData.value?.paxId || 0)
    const seatMapData = ref([])
    const ssrBags = ref([])
    const ssrOthers = ref([])

    const bookingId = ref(null)

    const ancillaryData = ref({})
    const passengerDataToAdd = ref([{
      ...cloneDeep(passengerData),
      paxType: 'ADULT',
      memberFly: {
        cardNumber: null,
        airline: '',
        infoFlyCardNumbers: [],
      },
    }])

    const isCreateGroupBooking = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getIsCreateGroupBooking`])
    const dataCreateGroupBooking = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getDataCreateGroupBooking`])
    const paxDataGroupBooking = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getPaxDataGroupBooking`])

    const isSplitItineraries = ref(false)

    const isDisableSplitItineraries = computed(() => {
      if (props.isCombination) return true

      const hasMultipleTrips = !isEmpty(selectedTrip.value) && selectedTrip.value.length > 1
      if (hasMultipleTrips) {
        const allFromSource1A = selectedTrip.value.every(trip => trip && trip.source === '1A')
        //     const containsNDC = selectedTrip.value.some(trip => trip && trip.contentSource === 'NDC')
        //     const containsGDS = selectedTrip.value.some(trip => trip && trip.contentSource === 'GDS')
        return allFromSource1A && !props.isCombination
      }

      return false
    })

    watchEffect(() => {
      if (isDisableSplitItineraries.value) {
        isSplitItineraries.value = true
      }
    })

    const addDataToSeatTable = passengerData => {
      // FIXME paxSeatData
      const paxSeatData = passengerData
        .filter(p => p.paxType !== 'INFANT')
        .map(p => ({
          firstName: p.firstName,
          lastName: p.lastName,
          paxId: p.paxId,
          paxType: p.paxType,
        }))
      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setDefaultDataPaxSeat`, paxSeatData)
    }

    const addPassenger = (paxType, addMore = false) => {
      const passenger = {
        ...passengerData,
        paxType,
        paxId: `${passengerDataToAdd.value.length + 1}`,
        PaxDocuments: passengerData.PaxDocuments.map(PaxDocument => ({ ...PaxDocument, paxId: `${passengerDataToAdd.value.length + 1}` })),
        memberFly: {
          cardNumber: null,
          airline: '',
        },
      }
      if (paxType === 'INFANT') passenger.parentPaxId = null
      if (addMore) store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/addPassenger`, paxType)
      passengerDataToAdd.value.push(passenger)
      if (addMore && paxType !== 'INFANT' && !isCreateGroupBooking.value) {
        addDataToSeatTable(passengerDataToAdd.value)
      }
    }

    watch(() => getSearchFlight.value, val => {
      if (!isCreateGroupBooking.value) {
        const { adult, child, infant } = val
        const totalSearchPassengers = adult + child + infant
        const totalPassenger = passengerDataToAdd.value.length
        if (totalPassenger <= totalSearchPassengers) {
          for (let i = 1; i < adult; i += 1) { addPassenger('ADULT') }
          for (let i = 0; i < child; i += 1) { addPassenger('CHILD') }
          for (let i = 0; i < infant; i += 1) { addPassenger('INFANT') }
        }
        addDataToSeatTable(passengerDataToAdd.value)
      } else {
        const { adult, child, infant } = paxDataGroupBooking.value
        const totalSearchPassengers = adult + child + infant
        const totalPassenger = passengerDataToAdd.value.length
        if (totalPassenger <= totalSearchPassengers) {
          for (let i = 1; i < adult; i += 1) { addPassenger('ADULT') }
          for (let i = 0; i < child; i += 1) { addPassenger('CHILD') }
          for (let i = 0; i < infant; i += 1) { addPassenger('INFANT') }
        }
      }

      // addDataToSeatTable(passengerDataToAdd.value)
    }, { immediate: true })

    // NOTE - GET LIST PASSENGERS
    const sortedPassengers = computed(() => {
      const order = { ADULT: 1, CHILD: 2, INFANT: 3 }
      passengerDataToAdd.value.forEach(pax => {
        if (pax.paxType === 'INFANT' && !pax.parentPaxId) {
          const parentIndex = passengerDataToAdd.value.findIndex(p => p.paxType === 'ADULT' && !p.childPaxId)
          pax.parentPaxId = parentIndex > -1 ? passengerDataToAdd.value[parentIndex].paxId : null
          passengerDataToAdd.value[parentIndex].childPaxId = pax.paxId
        }
      })
      const result = passengerDataToAdd.value.slice().sort((a, b) => {
        const paxTypeA = a.paxType.toUpperCase()
        const paxTypeB = b.paxType.toUpperCase()
        if (order[paxTypeA] < order[paxTypeB]) {
          return -1
        }
        if (order[paxTypeA] > order[paxTypeB]) {
          return 1
        }
        return 0
      })

      return result
    })

    // ANCHOR - DELETE PASSENGER
    function handleDeletePassenger(passenger) {
      if ((sortedPassengers.value.filter(p => p.paxType === 'ADULT').length === 1) && (passenger.paxType === 'ADULT')) {
        toastError({
          title: 'flight.cannotDeletePassenger',
          content: 'flight.leastOneAdult',
        })
        return
      }

      if (passenger.deleteAny) {
        const paxLists = cloneDeep(passengerDataToAdd.value)
        const lastPaxEqualPaxType = paxLists.filter(pax => pax.paxType === passenger.paxType)

        if (!isEmpty(lastPaxEqualPaxType)) {
          const paxToDel = lastPaxEqualPaxType[lastPaxEqualPaxType.length - 1]
          if (['ADULT'].includes(passenger.paxType) && !isEmpty(passengerDataToAdd.value.filter(p => p.paxType === 'INFANT'))) {
            const infantHasParentIndex = passengerDataToAdd.value.findIndex(p => p.parentPaxId && p.parentPaxId === paxToDel.paxId)
            if (infantHasParentIndex !== -1) {
              passengerDataToAdd.value[infantHasParentIndex].parentPaxId = null
            }
          }
          passengerDataToAdd.value = cloneDeep(paxLists.filter(pax => pax.paxId !== paxToDel.paxId))
          store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/deletePassenger`, passenger.paxType)
          if (passenger.paxType !== 'INFANT') addDataToSeatTable(passengerDataToAdd.value)
        }
      } else {
        const titleMsg = () => this.$createElement('div', {
          domProps: { innerHTML: this.$t('flight.confirmDeletePassenger') },
        })
        this.$bvModal
          .msgBoxConfirm(titleMsg(), {
            title: this.$t('modal.confirm'),
            size: 'sm',
            okVariant: 'info',
            okTitle: this.$t('modal.yes'),
            cancelTitle: this.$t('modal.no'),
            cancelVariant: 'outline-danger',
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value) {
              if (passenger.paxType === 'ADULT' && !isEmpty(passengerDataToAdd.value.filter(p => p.paxType === 'INFANT'))) {
                const infantHasParentIndex = passengerDataToAdd.value.findIndex(p => p.parentPaxId && p.parentPaxId === passenger.paxId)
                if (infantHasParentIndex !== -1) {
                  passengerDataToAdd.value[infantHasParentIndex].parentPaxId = null
                }
              }

              passengerDataToAdd.value = passengerDataToAdd.value.filter(el => el.paxId !== passenger.paxId)
              store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/deletePassenger`, passenger.paxType)
              if (passenger.paxType !== 'INFANT') addDataToSeatTable(passengerDataToAdd.value)
            }
          })
      }
    }

    function handleMultipleQuantityPaxChange(data) {
      if (isCreateGroupBooking.value) {
        // Tăng giảm số lượng pax trong modifierPax để show số lượng trong modalQuickInput
        store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/changePaxDataGroupBooking`, data)
      }

      data.forEach(item => {
        if (['increase'].includes(item.action)) {
          for (let i = 0; i < item.target; i += 1) {
            addPassenger(item.key, true)
          }
        }

        if (['decrease'].includes(item.action)) {
          for (let i = 0; i < item.target; i += 1) {
            const data = {
              paxType: item.key,
              deleteAny: true,
            }
            handleDeletePassenger(data)
          }
        }
      })
    }

    // handle update service amount
    function handleService(type, { ssrId, paxId }) {
      const ssrUpdate = cloneDeep(getAddonsData.value)
      const indexSsrUpdate = ssrUpdate.findIndex(ssr => ssr.ssrId === ssrId && ssr.paxId === paxId)

      if (indexSsrUpdate >= 0) {
        if (type === 'decrease') {
          ssrUpdate[indexSsrUpdate].amount -= 1
        }
        if (type === 'increase') {
          ssrUpdate[indexSsrUpdate].amount += 1
        }
        store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setAddonsSelectData`, ssrUpdate)
      } else {
        toastError({ title: 'Lỗi không tìm thấy gói dịch vụ được yêu cầu' })
      }
    }

    function handleDeleteService({ ssrId, paxId }) {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: 'Bạn có chắc chắn muốn xoá dịch vụ?' } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: 'XÁC NHẬN',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Xoá',
          cancelTitle: 'Trở lại',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            const index = getAddonsData.value.findIndex(item => item.ssrId === ssrId && item.paxId === paxId)
            if (index !== -1) {
              const ssrUpdate = cloneDeep(getAddonsData.value)
              ssrUpdate.splice(index, 1)
              store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setAddonsSelectData`, ssrUpdate)
              toastSuccess({
                title: 'Đã xoá gói mua thêm!',
              })
            }
          }
        })
    }

    // ANCHOR - Addons BAGGAGE, OTHER-SERVICES
    function getPaxSsrData(ssrs, tripId, source = '') {
      const ssrsByTripIdArr = ssrs.filter(ssr => ssr.tripId === tripId)

      return ssrsByTripIdArr.reduce((result, ssr) => {
        if (['VN'].includes(ssr.airline)) { // [VN1A] => airline === 'VN'
          const ssrData = {
            code: ssr.code,
            type: ['ABAG'].includes(ssr.code) ? 'BAGGAGE' : 'OTHER',
            name: ['ABAG'].includes(ssr.code) ? 'BAGGAGE' : 'OTHER',
            text: '',
            paxId: ssr.paxId,
            quantity: ssr.amount,
            price: ssr?.addonPrice,
            itineraryId: ssr?.itineraryId?.toString() || '', // NOTE: itineraryId -
            segmentId: ssr.segmentId,
            addonValue: ssr.addonValue.toString(),
          }
          result.push(ssrData)
        } else {
          for (let i = 0; i < ssr.amount; i += 1) {
            const ssrData = {
              code: ssr.code,
              type: ssr.type,
              name: ssr.name,
              details: ssr.details || [],
              paxId: ssr.paxId,
              itineraryId: ['VJ', 'VZ', 'VU'].includes(ssr.airline) || ['TH', '1A'].includes(source) ? (ssr?.itineraryId ?? tripId).toString() : '', // FIXME TH lấy tạm tripId, bao giờ có itineraryId thì bỏ đi
              segmentId: ssr.segmentId,
              addonValue: ssr.addonValue.toString(),
            }
            result.push(ssrData)
          }
        }
        return result
      }, [])
    }

    // ANCHOR - Addons SEAT
    function getPaxSeatData(tripId) {
      const selectedSeatData = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSelectedSeatsDetails`])
      if (selectedSeatData.value) {
        const result = []
        const segments = groupBy(selectedSeatData.value, item => item.seat.segmentFlights)
        forEach(segments, items => {
          const segment = items[0].seat
          const seatDetails = map(items, item => {
            const seat = item.seat.seat
            return {
              seatNumber: seat.seatName,
              paxId: item.passenger.paxId,
              ...(['VJ', 'VZ'].includes(segment.airline) && { seatValue: seat.fares[0].seatValue }),
              ...(['VN', 'VU'].includes(segment.airline) && { seatValue: seat.seatValue }),
              ...(['VN'].includes(segment.airline) && { price: seat?.fares[0]?.total }),
            }
          })
          result.push({
            boardPoint: segment.segmentFlights.split('-')[0],
            offPoint: segment.segmentFlights.split('-')[1],
            segmentId: segment?.segmentId,
            tripId: items[0]?.segment?.tripId,
            itineraryId: String(segment.itineraryId),
            seatDetails,
          })
        })

        return result.filter(item => item.tripId === tripId)
      }
      return []
    }

    const blankBookingData = ref({
      type: '',
      flightType: getSearchFlight.value.type,
      contact: getUserData().employeeData.id,
      airlineContact: null,
      paxContact: {
        paxCode: '2',
        firstName: '',
        lastName: '',
        phone: '',
        homePhone: '',
        address: '',
        country: 'VN',
        language: '',
        email: '',
      },
      paxLists: [],
      // paxssr: [],
      itineraries: [],
      // totalPrice: totalPriceFlight.value,
    })

    const bookingDataToAdd = ref(JSON.parse(JSON.stringify(blankBookingData.value)))

    const dataGroupBooking = ref(null)

    async function createBookingHandle(payload) {
      const isPaynow = payload?.payments
      const getMeEnablePaymentPassword = computed(() => store.getters['userStore/getMeEnablePaymentPassword'])
      const promoCodes = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getPromoCodes`])
      let paymentPassword
      if (getMeEnablePaymentPassword.value && isPaynow) {
        paymentPassword = await Vue.swal({
          title: this.$t('myAccount.paymentPassword.title'),
          html: '<div id="i-am-payment-password"></div>',
          didOpen: () => {
            new Vue({
              render: h => h(IAmPaymentPassword, { props: { typePaymentPassword: 'off' } }),
              i18n,
            }).$mount('#i-am-payment-password')
          },
          focusConfirm: false,
          preConfirm: () => new Promise(resolve => {
            const pwd = document.getElementById('swal-input-payment-password-1').value
            if (!validatorPaymentPassword(pwd)) {
              Vue.swal.showValidationMessage(this.$t('myAccount.paymentPassword.errorInput'))
            } else { resolve(pwd) }
          }),
          showCancelButton: true,
          confirmButtonText: this.$t('confirmation'),
          cancelButtonText: this.$t('cancel'),
          buttonsStyling: true,
          customClass: {
            confirmButton: 'btn btn-gradient',
            cancelButton: 'btn btn-danger mr-1',
          },
          allowOutsideClick: false,
          reverseButtons: true,
        })
        if (paymentPassword.isDenied || paymentPassword.isDismissed) {
          return
        }
      }

      store.dispatch('app/setLoading', true)

      if (isCreateGroupBooking.value) {
        createGroupBooking({
          ...payload,
          paymentPassword: paymentPassword ? paymentPassword.value : undefined,
          promoCodes: promoCodes.value?.length ? promoCodes.value : undefined,
        })
          .then(res => {
            // router.push({
            //   name: 'apps-reservations-modify',
            //   params: { id: res.resBooking.bookingId },
            // })
            dataGroupBooking.value = res

            toastSuccess({
              title: 'messagesList.success',
              content: 'messagesList.flight.createBookingSuccessful',
            })
          })
          .then(() => {
            root.$bvModal.show('modal-show-group-booking-created')
          })
          .catch(error => {
            console.warn(error)
            bookingDataToAdd.value = JSON.parse(JSON.stringify(blankBookingData.value))
            toastError({ title: 'messagesList.error', content: 'Lỗi giữ chỗ' })
          })
          .finally(() => store.dispatch('app/setLoading', false))
      } else {
        const payloadCreateBooking = {
          ...payload,
          paymentPassword: paymentPassword ? paymentPassword.value : undefined,
          promoCodes: promoCodes.value?.length ? promoCodes.value : undefined,
        }
        // FIXME Check case ko gửi tên hành khách lên => chưa tìm ra nguyên nhân tại sao lọt qua được validate
        if (payloadCreateBooking.paxLists.some(({ title, firstName, lastName }) => !title || !firstName || !lastName)) {
          toastError({
            title: 'messagesList.error',
            content: 'Lỗi nhập tên, vui lòng thử lại!!!',
          })
          return
        }
        createBooking(payloadCreateBooking)
          .then(res => {
            router.push({
              name: 'apps-reservations-modify',
              params: { id: res.resBooking.bookingId },
            })
            toastSuccess({
              title: 'messagesList.success',
              content: isPaynow ? 'messagesList.flight.paynowBookingSuccessful' : 'messagesList.flight.createBookingSuccessful',
            })
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            console.warn(error)
            bookingDataToAdd.value = JSON.parse(JSON.stringify(blankBookingData.value))
            toastError({ title: 'messagesList.error', content: 'Lỗi giữ chỗ' })
            root.$bvModal.hide('create-booking-verify-and-confirm-price')
          })
          .finally(() => store.dispatch('app/setLoading', false))
      }
    }

    function resetAgencyData() {
      agencyData.value = isRoleF1.value ? { ...meDataStore.value } : {
        ...store.getters['userStore/getAgencyData'],
        phoneNumber: store.getters['userStore/getAgencyData']?.agencyOwner?.phoneNumber,
      }
    }

    const checkLengthPaxsName = ref(false)
    const errLengthNameList = ref([])
    const checkDuplicatePaxName = ref(false)
    const contentTextError = ref([])
    const payType = ref(null)

    async function openModalOverLimitLengthPaxsName(data, pay) {
      await new Promise(resolve => {
        payType.value = pay
        errLengthNameList.value = data
        resolve()
      })
    }

    function modifyPayload() {
      // NOTE - check trùng tên pax 1S (title + họ + tên)
      if (selectedTrip.value.some(trip => trip.source.includes('1S')) && sortedPassengers.value?.length) {
        checkDuplicatePaxName.value = sortedPassengers.value.some((pax, indexPax) => {
          const lastIndex = sortedPassengers.value.findLastIndex(({ title, firstName, lastName }) => title === pax.title && firstName === pax.firstName && lastName === pax.lastName)
          return lastIndex !== indexPax
        })
      }

      if (selectedTrip.value.some(trip => ['QH'].includes(trip.source) || (trip.source.includes('1G') && trip.contentSource && trip.contentSource.includes('NDC')))) {
        checkLengthPaxsName.value = false
        contentTextError.value = []

        const sources = selectedTrip.value.map(t => t.source)
        const pooledData = pooledPaxCheckMaxLength(cloneDeep(passengerDataToAdd.value), sources)

        if (!isEmpty(pooledData)) {
          const overLimitOnePaxArr = pooledData.filter(item => item.type === 'one' && item.isOverLimit)
          const overLimitInfPaxArr = pooledData.filter(item => item.type === 'double' && item.isOverLimit)

          if (!isEmpty(overLimitOnePaxArr) || !isEmpty(overLimitInfPaxArr)) {
            contentTextError.value.push(...overLimitOnePaxArr, ...overLimitInfPaxArr)
            checkLengthPaxsName.value = true
          }
        }
      }

      bookingDataToAdd.value.bookingId = bookingId.value ? bookingId.value : undefined

      bookingDataToAdd.value.splitItineraries = props.isCombination
        ? undefined
        : (
          ['RT', 'MC'].includes(bookingDataToAdd.value.flightType) && selectedTrip.value.some(t => t.source === '1G')
            ? true
            : isSplitItineraries.value
        )
      bookingDataToAdd.value.type = selectedTrip.value[0].source === '1G' ? '1G' : selectedTrip.value[0].source // operator

      // ANCHOR - CUSTOMER INFO
      if (!customerData.value) {
        bookingDataToAdd.value.paxContact = null
      } else if (customerData.value.firstName || customerData.value.lastName) {
        bookingDataToAdd.value.paxContact = customerData.value
      } else {
        bookingDataToAdd.value.paxContact = {
          ...customerData.value,
          firstName: sortedPassengers.value[0].firstName,
          lastName: sortedPassengers.value[0].lastName,
        }
      }

      // ANCHOR - AIRLINE CONTACT INFO
      bookingDataToAdd.value.airlineContact = {
        phoneNumber: checkboxCustomerDataReplaceAgencyData.value ? customerData.value.phone : agencyData.value.phoneNumber,
        email: checkboxCustomerDataReplaceAgencyData.value && customerData.value.email ? customerData.value.email : agencyData.value.emailAddress,
      }

      // ANCHOR - PAXLIST INFO
      bookingDataToAdd.value.paxLists = cloneDeep(sortedPassengers.value).map(passenger => {
        const {
          servicePackage,
          serviceAmount,
          ...passengerData
        } = passenger

        if (!passengerData.memberFly.cardNumber) {
          delete passengerData.memberFly
        } else {
          passengerData.memberFly.supplierCode = passengerData.memberFly.airline
        }

        return ({
          ...passengerData,
          // ...(passengerData.memberFly && { memberFly: passengerData.memberFly.map(item => ({ ...item, cardNumber: String(item.cardNumber) })) }),
          ...(passengerData.memberFly && {
            memberFly: [{
              cardNumber: String(passengerData.memberFly.cardNumber),
              airline: passengerData.memberFly.airline,
              supplierCode: passengerData.memberFly.supplierCode,
            }],
          }),
          PaxDocuments: ['1G', 'VJ', 'F1', 'AK', '1A'].includes(selectedTrip.value[0].source)
            ? passengerData.PaxDocuments
              .filter(item => item.isCountry && item.endDate && item.number && item.nationality && (selectedTrip.value.some(t => ['1A'].includes(t.source)) ? item.gender : true))
              .map(document => {
                if (!passenger.birthday) {
                  toastError({
                    title: 'messagesList.flight.errMissingDoB',
                    // content: 'messagesList.flight.errMaxLengthPaxContent',
                  })
                  return []
                }

                return {
                  ...document,
                  nationality: isSourceUseShortCodeForDocument.value ? document.nationality.shortCode : document.nationality.longCode,
                  isCountry: isSourceUseShortCodeForDocument.value ? document.isCountry.shortCode : document.isCountry.longCode,
                  birthday: new Date(passenger.birthday).toISOString(),
                }
              })
            : [],
          birthday: (passenger.birthday) ? new Date(passenger.birthday).toISOString() : undefined,
        })
      })

      // ANCHOR - TRIPS INFO
      bookingDataToAdd.value.itineraries.splice(0)
      selectedTrip.value.forEach((trip, tripIndex) => {
        const {
          fareOptions, clientId, bookingKey, source,
        } = trip
        const { promotionInfo, promotionParent } = fareOptions
        const groupPassengers = groupBy(passengerDataToAdd.value, item => item.paxType)
        bookingDataToAdd.value.itineraries.push(
          {
            domestic: trip.domestic, // for calculate agencyFee
            source,
            airline: trip?.airline || null,
            clientId: clientId || '',
            bookingKey: bookingKey || 'string',
            itineraryId: ['VN1A', 'TH', '1A'].includes(trip.source) ? trip?.itineraryId?.toString() : (trip.segments[0]?.segmentValue || null),
            fareBreakdowns: Object.keys(groupPassengers).map(paxType => {
              const count = groupPassengers[paxType].length
              let discountAmount = 0
              let discountAmountParent = 0
              if (paxType === 'ADULT') {
                if (promotionInfo) discountAmount = promotionInfo.promotionAdult * count
                if (promotionParent) discountAmountParent = promotionParent.promotionAdult * count
              }
              if (paxType === 'CHILD') {
                if (promotionInfo) discountAmount = promotionInfo.promotionChild * count
                if (promotionParent) discountAmountParent = promotionParent.promotionChild * count
              }

              return {
                paxType,
                netFare: fareOptions[`fare${capitalize(paxType)}`] || 0,
                discountAmount,
                discountAmountParent,
                tax: (fareOptions[`tax${capitalize(paxType)}`] + fareOptions[`surcharge${capitalize(paxType)}`]) || 0,
                total: fareOptions[`total${capitalize(paxType)}`] || 0,
                fareValue: fareOptions.fareValue || '',
              }
            }),
            segments: trip.segments.map(segment => {
              let segmentValue = ''

              if (segment.segmentValue) {
                const segmentValueArr = segment.segmentValue.split(' | ')
                segmentValue = (segment.airline === 'QH' && source !== '1G')
                  ? `${segmentValueArr[0]} | ${segmentValueArr[1]} | ${fareOptions.bookingClass}`
                  : segment.segmentValue
              }

              const fareBasisCodeSplit = fareOptions?.fareBasisCode?.split(' | ') || []
              const bookingClassSplit = fareOptions?.bookingClass?.split(' | ') || []
              const groupClassSplit = fareOptions?.groupClass?.split(' | ') || []

              return {
                leg: segment.leg,
                airline: segment.airline,
                departure: segment.departure.IATACode,
                arrival: segment.arrival.IATACode,
                departureTime: convertISODateTime(segment.departure.at, segment.departure.timezone).ISOdatetime,
                arrivalTime: convertISODateTime(segment.arrival.at, segment.arrival.timezone).ISOdatetime,
                flightNumber: segment.flightNumber.toString(),
                fareType: fareOptions?.fareType?.split(' | ')[segment.leg - 1]?.toString() || fareOptions?.fareType || '',
                fareBasisCode: ['QH'].includes(segment.airline) ? fareOptions.fareBasisCode : (fareBasisCodeSplit[segment.leg - 1]?.toString() || fareBasisCodeSplit[fareBasisCodeSplit.length - 1]?.toString() || fareOptions.fareBasisCode) || '',
                bookingClass: ['QH'].includes(segment.airline) ? fareOptions.bookingClass : (bookingClassSplit[segment.leg - 1]?.toString() || segment.bookingClass) || '',
                groupClass: groupClassSplit[segment.leg - 1]?.toString() || fareOptions.groupClass || '',
                marriageGrp: segment.marriageGrp,
                segmentValue: segmentValue || '',
                bookingClassId: segment?.bookingClassId || '',
                segmentId: segment.segmentId || (segment.airlineSegmentId ? String(segment.airlineSegmentId) : ''),
                operating: segment.operating || '',
                departureTerminal: ['TAP'].includes(clientId) ? segment.departure.terminal : undefined,
                arrivalTerminal: ['TAP'].includes(clientId) ? segment.arrival.terminal : undefined,
                airCraft: ['TAP'].includes(clientId) ? segment.airCraft : undefined,
              }
            }),
            discountInfo: promotionInfo,
            discountParent: promotionParent,

            // NOTE Add-ons
            paxssr: getPaxSsrData(getAddonsData.value, trip.segments[0].tripId || tripIndex + 1, source),
            paxSeat: getPaxSeatData(trip.segments[0].tripId || tripIndex + 1),
          },
        )
      })
    }

    const loading = ref(false)
    const customFeeServiceDataToModify = computed(() => store.getters['app-flight-v2/getCustomFeeServiceDataToModify'])
    const getSelectedProfileFeeService = computed(() => store.getters['app-flight-v2/getSelectedProfileFeeService'])

    const AIRLINE_F1_MUST_PAYNOW = ['AK', 'FD', '5J']
    function handleValidateBooking(pay) {
      loading.value = true
      checkLengthPaxsName.value = false

      if (pay === PAY_LATER && bookingDataToAdd.value.itineraries.some(itinerary => itinerary.source === 'AK')) {
        toastError({
          title: 'Chưa hỗ trợ giữ chỗ cho chuyến bay thuộc hệ thống Air Asia (AK). Vui lòng thanh toán ngay!',
        })
        loading.value = false
        return
      }

      // Fly one must pay now
      if (pay === PAY_LATER && selectedTrip.value.some(itinerary => itinerary.source === 'F1' && itinerary.segments.some(i => AIRLINE_F1_MUST_PAYNOW.includes(i.airline)))) {
        toastError({
          title: 'Chưa hỗ trợ giữ chỗ cho chuyến bay hãng AK, FD và 5J thuộc hệ thống Other (F1) . Vui lòng thanh toán ngay!',
        })
        loading.value = false
        return
      }

      this.$refs.refFormReservation.validate()
        .then(success => {
          if (success) {
            modifyPayload()

            // Handle checkDuplicatePaxName
            if (checkDuplicatePaxName.value) {
              const titleMsg = () => {
                const contentVNode = this.$createElement('div', { class: [], style: '' }, ['Tên hành khách (cả danh xưng) bị trùng nhau. Vui lòng kiểm tra lại tên hành khách.'])
                return contentVNode
              }
              this.$bvModal
                .msgBoxOk(titleMsg(), {
                  title: 'Lỗi trùng tên hành khách !!!',
                  size: 'sm',
                  okVariant: 'danger',
                  okTitle: this.$t('modal.close'),
                  hideFooter: true,
                  centered: true,
                })
              return
            }

            bookingDataToAdd.value.paxLists.forEach(pax => {
              pax.customFeeService = customFeeServiceDataToModify.value[`${pax.paxType.toLowerCase()}Amount`] ?? undefined
            })

            bookingDataToAdd.value.profileFeeId = getSelectedProfileFeeService.value?.id ?? undefined

            if (pay === PAY_NOW) {
              if (!store.getters['userStore/canIssueLimit']('QH')) {
                toastError({
                  title: 'messagesList.flight.uAccountCannotPayNow',
                })
                return
              }

              if (selectedTrip.value.every(trip => ['QH'].includes(trip.airline) && trip.source !== '1G')) {
                if (checkLengthPaxsName.value) {
                  toastError({
                    title: 'messagesList.flight.errMaxLengthPax',
                    content: 'messagesList.flight.errMaxLengthPaxContent',
                  })

                  openModalOverLimitLengthPaxsName(contentTextError.value, pay)

                  this.$bvModal.show('create-booking-err-length-passenger-qh')
                } else {
                  this.$bvModal.show('create-booking-confirm-price-modal')
                }
              } else {
                this.$bvModal.show('create-booking-confirm-price-modal')
              }
            } else {
              const ischeckLengthPaxsName = selectedTrip.value.some(trip => ['QH'].includes(trip.source))
              const isCheckLength1G = selectedTrip.value.some(trip => trip.source.includes('1G') && trip.contentSource && trip.contentSource.includes('NDC'))
              const isVerifyPrice = selectedTrip.value.every(trip => ['VN1A'].includes(trip.source) && !['TAP'].includes(trip.clientId)) || selectedTrip.value.every(trip => ['1A'].includes(trip.source))

              // eslint-disable-next-line no-lonely-if
              if ((ischeckLengthPaxsName || isCheckLength1G)) {
                if (checkLengthPaxsName.value) {
                  toastError({
                    title: 'messagesList.flight.errMaxLengthPax',
                    content: 'messagesList.flight.errMaxLengthPaxContent',
                  })
                  openModalOverLimitLengthPaxsName(contentTextError.value)
                  this.$bvModal.show('create-booking-err-length-passenger-qh')
                } else if (isVerifyPrice) {
                  this.$bvModal.show('create-booking-verify-and-confirm-price')
                } else {
                  createBookingHandle(bookingDataToAdd.value)
                }
              } else if (isVerifyPrice) {
                this.$bvModal.show('create-booking-verify-and-confirm-price')
              } else {
                createBookingHandle(bookingDataToAdd.value)
              }
            }
          } else {
            toastError({ title: 'messagesList.error', content: 'messagesList.flight.errCheck' })
          }
        })
        .finally(() => { loading.value = false })
    }

    function modifyNameToCreateBooking(payType) {
      const infantArrToFixName = errLengthNameList.value.filter(p => p.type === 'double' && p.canModify && p.isOverLimit)

      infantArrToFixName.forEach(p => {
        const findInfant = bookingDataToAdd.value.paxLists.find(pax => pax.paxId === p.infantPaxId)
        if (findInfant) {
          findInfant.firstName = p.strNameInfant
        } else {
          console.error('Lỗi thay thế tên của em bé.')
        }
      })

      const isVerifyPrice = selectedTrip.value.every(trip => ['VN1A'].includes(trip.source) && !['TAP'].includes(trip.clientId)) || selectedTrip.value.every(trip => ['1A'].includes(trip.source))
      if (payType === 'payNow') {
        this.$bvModal.show('create-booking-confirm-price-modal')
      } else if (isVerifyPrice) {
        this.$bvModal.show('create-booking-verify-and-confirm-price')
      } else {
        createBookingHandle(bookingDataToAdd.value)
      }
    }

    function checkAlertSegments(data) {
      for (let i = 0; i < data.segments.length - 1; i += 1) {
        const segment = data.segments[i]
        const nextSegment = data.segments[i + 1]
        const { isNextAirport, isNextDay, transitTime } = checkTransitAlert(segment.arrival, nextSegment.departure)
        Object.assign(segment, {
          isNextAirport, isNextDay, transitTime,
        })
      }
    }

    watch(() => [selectedTrip.value, getSearchFlight.value], async ([selectedTripData, getSearchFlightData]) => {
      if (selectedTripData && getSearchFlightData) {
        // NOTE: Add alert giữa chặng
        selectedTrip.value.forEach(trip => {
          checkAlertSegments(trip)
        })

        const isEveryTripFromAK = selectedTripData.every(trip => ['AK'].includes(trip.source))
        const tapVN1A = selectedTripData.some(trip => ['TAP'].includes(trip.clientId) && ['VN1A'].includes(trip.source))
        const existAirlinesSpAncillary = selectedTripData.filter(trip => sourcesCanBuyAncillary.value.includes(trip.source) && !['TAP'].includes(trip.clientId))
        const existAirlinesSpSeatMap = selectedTripData.filter(trip => sourcesCanBuySeatMap.value.includes(trip.source) && !['TAP'].includes(trip.clientId))

        if (tapVN1A) {
          modifyPayload()
          const res = await apiBooking.draftBooking({
            ...bookingDataToAdd.value,
            employeeCode: meDataStore.value?.employeeCode,
          })

          if (res?.bookingId) {
            bookingId.value = res.bookingId
          }
        }

        if (((!isEmpty(existAirlinesSpAncillary) || !isEmpty(existAirlinesSpSeatMap)) && !isCreateGroupBooking.value) || isEveryTripFromAK) {
          if (isEveryTripFromAK) {
            modifyPayload()
            // ANCHOR - PROMO CODE
            const promoCodes = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getPromoCodes`])
            const promoCodeAK = (promoCodes.value ?? []).find(item => item.airline === 'AK')

            if (promoCodeAK) {
              bookingDataToAdd.value.promoCode = promoCodeAK.code
            }

            await apiBooking.draftBooking({
              ...bookingDataToAdd.value,
              employeeCode: meDataStore.value?.employeeCode,
            })
          }

          if (!isEmpty(existAirlinesSpSeatMap)) {
            fetchSeatMap(existAirlinesSpSeatMap, getSearchFlightData, bookingId.value)
              .then(res => {
                seatMapData.value = res
              })
          }

          if (!isEmpty(existAirlinesSpAncillary)) {
            fetchListAncillary(existAirlinesSpAncillary, getSearchFlightData, bookingId.value, props.isCombination)
              .then(res => {
                ancillaryData.value = res
                ssrBags.value = res?.ssrBags || []
                ssrOthers.value = res?.ssrOthers || []
              })
              .catch(e => {
                console.error(e)
              })
          }
        }
      }
    }, { deep: true, immediate: true })

    // handle cancel
    function handleCancel() {
      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/resetDataTrips`)
      router.go(-1)
    }

    const setReload = setTimeout(() => {
      if (isEmpty(selectedTrip.value.flat())) {
        router.replace({ name: 'apps-flightsV2-result' })
      }
    }, 1000)

    onBeforeUnmount(() => {
      if (!isCreateGroupBooking.value) updatePassengerCountToLocalStorage(passengerDataToAdd.value)
      clearTimeout(setReload)
    })

    const isVisiblePayNow = computed(() => {
      if (!selectedTrip.value) return false
      let tempAirline = null
      return selectedTrip.value.every(trip => {
        if (!tempAirline) tempAirline = trip.airline
        return trip.airline === tempAirline && (
          (['QH', 'VJ', 'VZ', 'FD'].includes(trip.airline) && ['QH', 'VJ', 'VZ', 'FD'].includes(trip.source))
          || (['F1'].includes(trip.source) && AIRLINE_F1_MUST_PAYNOW.includes(trip.airline))
          || ['AK'].includes(trip.source)
        )
      })
    })

    const isVisiblePayLater = computed(() => {
      if (selectedTrip.value.some(trip => (['F1'].includes(trip.source) && AIRLINE_F1_MUST_PAYNOW.includes(trip.airline)) || ['AK'].includes(trip.source))) {
        return false
      }
      return true
    })

    const isCollapsedAgencyAndContactDetail = ref(true)
    function toggleIsCollapsedAgencyAndContactDetail() {
      isCollapsedAgencyAndContactDetail.value = !isCollapsedAgencyAndContactDetail.value
    }

    return {
      isEmpty,
      getSearchFlight,
      selectedTrip,
      contentTextError,

      PAY_LATER,
      PAY_NOW,

      modifyNameToCreateBooking,
      payType,
      customerData,
      checkboxCustomerDataReplaceAgencyData,
      agencyData,
      resetAgencyData,
      customerId,
      bookingDataToAdd,

      sortedPassengers,

      ancillaryData,
      seatMapData,

      handleCancel,
      handleValidateBooking,
      createBookingHandle,
      handleService,
      handleDeleteService,
      handleDeletePassenger,
      loading,

      isSplitItineraries,
      isVisiblePayNow,
      isVisiblePayLater,
      isDisableSplitItineraries,

      isRoleF1,
      isRoleF3,
      enableSendEmployeeMailAirline,
      checkSendEmployeeMailAirline,
      errLengthNameList,

      handleMultipleQuantityPaxChange,
      isCreateGroupBooking,
      dataCreateGroupBooking,
      paxDataGroupBooking,
      dataGroupBooking,
      sourcesCanBuyAncillary,
      sourcesCanBuySeatMap,
      isCollapsedAgencyAndContactDetail,
      toggleIsCollapsedAgencyAndContactDetail,

      ssrBags,
      ssrOthers,

      isHideFunction,
      toggleHideFunction,
    }
  },
}
</script>
